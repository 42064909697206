var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('BCard', {
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "infoOutline",
      "size": "25"
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.detail.title')) + " ")])], 1), _c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_vm.invoiceTicketData ? _c('InvoiceTicketForm', {
    attrs: {
      "invoiceTicket": _vm.invoiceTicketData,
      "isEditing": _vm.isEditing
    },
    on: {
      "update:invoiceTicket": function updateInvoiceTicket($event) {
        _vm.invoiceTicketData = $event;
      },
      "update:invoice-ticket": function updateInvoiceTicket($event) {
        _vm.invoiceTicketData = $event;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex-center gap-2 my-1"
  }, [_c('BButton', {
    staticClass: "width-150",
    attrs: {
      "pill": "",
      "variant": "outline-secondary",
      "to": {
        name: 'apps-invoiceTicketList'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.btn.back')) + " ")]), !_vm.isEditing ? _c('BButton', {
    staticClass: "width-150",
    attrs: {
      "pill": "",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEditing = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.btn.edit')) + " ")]) : _c('BButton', {
    staticClass: "width-150",
    attrs: {
      "pill": "",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.resetForm();
        _vm.isEditing = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.btn.cancelEdit')) + " ")]), _vm.isEditing ? _c('BButton', {
    staticClass: "width-150",
    attrs: {
      "pill": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.onSaveHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceTickets.btn.save')) + " ")]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }