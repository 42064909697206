<template lang="">
  <IAmOverlay :loading="loading">
    <BCard body-class="p-50">
      <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
        <IAmIcon
          icon="infoOutline"
          size="25"
          class="p-1 icon-card"
        />
        <span class="font-medium-3 m-0 fw-700">
          {{ $t('invoice.invoiceTickets.detail.title') }}
        </span>
      </div>
      <ValidationObserver ref="refFormObserver">
        <InvoiceTicketForm
          v-if="invoiceTicketData"
          :invoiceTicket.sync="invoiceTicketData"
          :isEditing="isEditing"
        />

        <div class="d-flex-center gap-2 my-1">
          <BButton
            pill
            class="width-150"
            variant="outline-secondary"
            :to="{ name: 'apps-invoiceTicketList' }"
          >
            {{ $t('invoice.invoiceTickets.btn.back') }}
          </BButton>

          <BButton
            v-if="!isEditing"
            pill
            class="width-150"
            variant="primary"
            @click="isEditing = true"
          >
            {{ $t('invoice.invoiceTickets.btn.edit') }}
          </BButton>
          <BButton
            v-else
            pill
            class="width-150"
            variant="danger"
            @click="resetForm(); isEditing = false"
          >
            {{ $t('invoice.invoiceTickets.btn.cancelEdit') }}
          </BButton>

          <BButton
            v-if="isEditing"
            pill
            class="width-150"
            variant="primary"
            @click="onSaveHandle"
          >
            {{ $t('invoice.invoiceTickets.btn.save') }}
          </BButton>
        </div>
      </ValidationObserver>
    </BCard>
  </IAmOverlay>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { BButton, BCard } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'

import { getDifference, useRouter } from '@/@core/utils/utils'
import { apiInvoice } from '@/api'
import IAmOverlay from '@/components/IAmOverlay.vue'

import formValidation from '@core/comp-functions/forms/form-validation'

import useToast from '@useToast'

import InvoiceTicketForm from './InvoiceTicketForm.vue'

export default {
  components: {
    InvoiceTicketForm,
    IAmOverlay,
    BCard,
    ValidationObserver,
    BButton,
  },
  setup() {
    const { refFormObserver } = formValidation()

    const { route } = useRouter()
    const { toastError, toastSuccess } = useToast()
    const isEditing = ref(false)
    const invoiceTicketRaw = ref()
    const invoiceTicketData = ref()
    const loading = ref(false)
    async function getInvoiceTicketById(id) {
      loading.value = true
      try {
        const res = await apiInvoice.getInvoiceTicketById(id)
        invoiceTicketRaw.value = cloneDeep(res.data)
        invoiceTicketData.value = cloneDeep(res.data)
      } catch (error) {
        toastError('Lỗi tải dữ liệu')
      } finally {
        loading.value = false
      }
    }
    watch(route, () => {
      getInvoiceTicketById(route.value.params.id)
      isEditing.value = Boolean(route.value.query?.edit ?? false)
    }, { immediate: true })

    function resetForm() {
      invoiceTicketData.value = cloneDeep(invoiceTicketRaw.value)
    }

    async function onSaveHandle() {
      const valid = await refFormObserver.value.validate()
      if (valid) {
        loading.value = true
        const payload = {
          bookingCode: invoiceTicketData.value.bookingCode || undefined,
          ticketNumber: invoiceTicketData.value.ticketNumber || undefined,
          customerName: invoiceTicketData.value.customerName || undefined,
          flightTrip: invoiceTicketData.value.flightTrip || undefined,
          flightDate: invoiceTicketData.value.flightDate || undefined,
          type: invoiceTicketData.value.type,
          valueAddedTax: invoiceTicketData.value.valueAddedTax,
          unitPrice: Number(invoiceTicketData.value.unitPrice),
          collectionFee: Number(invoiceTicketData.value.collectionFee),
        }
        const differencePayload = getDifference(payload, invoiceTicketRaw.value)
        try {
          const res = await apiInvoice.updateInvoiceTicket(invoiceTicketRaw.value.id, differencePayload)
          toastSuccess('Cập nhật vé thành công')
          if (res.id) {
            isEditing.value = false
            getInvoiceTicketById(route.value.params.id)
          }
        } catch (error) {
          toastError('Lỗi cập nhật')
        } finally {
          loading.value = false
        }
      }
    }
    return {
      invoiceTicketData,
      isEditing,
      loading,
      refFormObserver,
      onSaveHandle,
      resetForm,
    }
  },
}
</script>
<style lang="">

</style>
